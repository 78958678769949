<template>
  <div>
    <div v-for="(card, index) in railcards" :key="`card-${index}`" class="railcard">
      <q-select
        v-model="railcards[index]"
        stack-label
        :label="$t(`railcard_concession.${type}.default`)"
        :options="railcardOptions"
        option-label="name"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @input="updateRailcards"
      />
    </div>
    <div v-if="railcards[0].code" class="buttons">
      <button id="add-railcard" @click.prevent="addRailcard">
        {{ $t(`railcard_concession.${type}.add`) }}
      </button>
      <button class="remove-railcard" @click.prevent="removeRailcard">
        {{ $t(`railcard_concession.${type}.remove`) }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Railcards',
  props: {
    railcardOptions: Array,
    adults: Number,
    children: { type: Number, default: 0 },
    type: String
  },
  data () {
    return {
      railcards: [{ name: this.$t('none_selected'), code: null }]
    }
  },
  methods: {
    addRailcard () {
      const totalConcessions = this.adults + this.children
      if (this.railcards.length < totalConcessions) {
        this.railcards.push({ name: this.$t('none_selected'), code: null })
        this.updateRailcards()
      } else this.$q.notify(this.$t(`railcard_concession.${this.type}.more_than_adults`))
    },
    removeRailcard () {
      this.railcards.pop()
      // If no more railcards, set back to blank
      if (!this.railcards.length) this.addRailcard()
      this.updateRailcards()
    },
    updateRailcards () {
      this.$emit('update', this.railcards)
    }
  }
}
</script>

<style lang="stylus" scoped>
.buttons
  width 100%
  display flex
  justify-content space-between

#add-railcard
  background none
  border none
  color var(--q-color-primary)
  font-size 90%
  padding 5px
  text-align left

.railcard
  display flex
  align-items center
  justify-content stretch
  width 100%
  .q-field
    flex-grow 1

.remove-railcard
  background none
  border none
  color $negative
  font-size 90%
</style>
